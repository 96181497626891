// ThemeToggle.js
import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSun, faMoon } from '@fortawesome/free-solid-svg-icons';
import './ThemeToggle.css'; // Ensure you have styles for the icons

const ThemeToggle = ({ theme, toggleTheme }) => {
  return (
    <div className="theme-toggle" onClick={toggleTheme}>
      {theme === 'day' ? (
        <FontAwesomeIcon className='sun' icon={faSun} size="2x" />
      ) : (
        <FontAwesomeIcon icon={faMoon} size="2x" />
      )}
    </div>
  );
};

export default ThemeToggle;
